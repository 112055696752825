import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../styles/output.css";

function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef(null);
    const dropdownRef = useRef(null);
    const menuOpenRef = useRef(false);

    const [stripeColor, setStripeColor] = useState('rainbow-effect');

    useEffect(() => {
        if (location.pathname.startsWith('/research')) {
            setStripeColor('bg-red-500');
        } else if (location.pathname.startsWith('/projects')) {
            setStripeColor('bg-blue-500');
        } else if (location.pathname.startsWith('/about')) {
            setStripeColor('bg-green-500');
        } else if (location.pathname.startsWith('/contact')) {
            setStripeColor('bg-purple-500');
        } else {
            setStripeColor('rainbow-effect');
        }
    }, [location.pathname]);
    

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleMenuClick = () => {
        if (menuRef.current && dropdownRef.current) {
            if (menuRef.current.classList.contains('rotate-180')) {
                menuRef.current.classList.remove('rotate-180');
                dropdownRef.current.classList.add('hidden');
                menuOpenRef.current = false;
            } else {
                menuRef.current.classList.add('rotate-180');
                dropdownRef.current.classList.remove('hidden');
                menuOpenRef.current = true;
            }
        }
    };

    const handleClickOutside = (event) => {
        if (
            menuOpenRef.current &&
            menuRef.current &&
            dropdownRef.current &&
            !menuRef.current.contains(event.target) &&
            !dropdownRef.current.contains(event.target)
        ) {
            menuRef.current.classList.remove('rotate-180');
            dropdownRef.current.classList.add('hidden');
            menuOpenRef.current = false;
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="Navbar bg-black/80 w-screen h-[60px] fixed top-0 left-0 flex items-center z-40 backdrop-blur-sm">
            <div className={`absolute left-0 top-0 w-screen h-[2px] transition-colors duration-500 ${stripeColor}`}></div>
            <div 
                className="flex items-center h-full ml-10 hover:cursor-pointer"
                onClick={handleLogoClick}
            >
                <img
                    src="/static/ADavid03LOGO-textonly-white.png"
                    alt="ADavid03 Logo"
                    width={40}
                    height={40}
                />
                <h3 className="text-2xl ml-4 text-white font-hassDisplayBlack">
                    Alexandre David
                </h3>
            </div>
            <div className="flex items-center h-full xl:ml-[150px] lg:ml-[25px] gap-[100px] md:gap-[50px] md:ml-[25px] w-fit hidden md:flex">
            <Link to="/research" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 ${location.pathname.includes('/research') ? 'border-red-500' : 'border-transparent hover:border-white'}`}>
                    Research
                </Link>
                <Link to="/projects" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 ${location.pathname.includes('/projects') ? 'border-blue-500' : 'border-transparent hover:border-white'}`}>
                    Projects
                </Link>
                <Link to="/about" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 ${location.pathname.includes('/about') ? 'border-green-500' : 'border-transparent hover:border-white'}`}>
                    About
                </Link>
                <Link to="/contact" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 ${location.pathname.includes('/contact') ? 'border-purple-500' : 'border-transparent hover:border-white'}`}>
                    Contact
                </Link>
            </div>
            <svg 
                ref={menuRef} 
                onClick={handleMenuClick} 
                className="absolute right-2 cursor-pointer md:hidden w-8 h-8 mr-10 text-white" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
            <div 
                ref={dropdownRef} 
                className="absolute right-0 top-[60px] h-fit w-fit px-10 py-5 bg-black hidden md:hidden"
            >
                <div className="flex flex-col justify-center items-center h-full gap-10 my-10">
                    <Link to="/research" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 border-black ${location.pathname.includes('/research') ? 'border-red-500' : 'border-black hover:border-white'}`}>
                        Research
                    </Link>
                    <Link to="/projects" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 border-black ${location.pathname.includes('/projects') ? 'border-blue-500' : 'border-black hover:border-white'}`}>
                        Projects
                    </Link>
                    <Link to="/about" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 border-black ${location.pathname.includes('/about') ? 'border-green-500' : 'border-black hover:border-white'}`}>
                        About
                    </Link>
                    <Link to="/contact" className={`text-white font-hassDisplay55 hover:border-b-2 border-b-2 border-black ${location.pathname.includes('/contact') ? 'border-yellow-500' : 'border-black hover:border-white'}`}>
                        Contact
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
