import { useState, useEffect } from "react";
import "../styles/output.css";

function Home() {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Define the threshold in pixels before the header starts zooming
  const scrollThreshold = 0;

  // Calculate zoom and opacity based on scroll position after threshold
  const zoomFactor = scrollY > scrollThreshold ? Math.min(10, 1 + (scrollY - scrollThreshold) / 100) : 1;
  const opacity = scrollY > scrollThreshold ? Math.max(0, 1 - (scrollY - scrollThreshold) / 300) : 1;

  return (
    <div className="flex flex-col min-h-screen">
      <div
        className="flex items-center justify-center w-full h-[calc(100vh-60px)]"
        style={{
          position: 'fixed',
          top: 0,
          transform: `scale(${zoomFactor})`,
          opacity: opacity,
          transition: 'transform 0.3s ease, opacity 0.3s ease',
        }}
      >
        <h1
          className="text-8xl text-center font-hassDisplayBlack text-black dark:text-white tracking-wide"
        >
          ALEXANDRE DAVID
        </h1>
      </div>
      {/* Mouse icon fades out smoothly as user scrolls */}
      <div
        className={`flex items-center justify-center w-full transition-opacity duration-500 absolute bottom-5 ${
          scrollY > 0 ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="mouse"></div>
      </div>
    </div>
  );
}

export default Home;
