import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../components/Tooltip';
import "../styles/output.css";

function Research() {
    return (
        <div className="Container">
            <h1 className="text-2xl font-hassDisplayBlack text-black dark:text-white tracking-wide">Research</h1>
            <input type="text" className="lg:w-1/2 md:w-full sm:w-full mt-5 p-2 border-2 border-neutral-200 text-black dark:text-white dark:border-neutral-800 rounded-md bg-transparent" placeholder="Search research..." />
            <h1 className="mt-10 text-xl font-light font-hassDisplay75 text-neutral-600 dark:text-neutral-400 tracking-wide">2024:</h1>
        </div>
    );
}

export default Research;
