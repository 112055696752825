import React, { useState, useRef } from 'react';
import '../styles/output.css';

function Tooltip({ children, tooltipText }) {
    const [show, setShow] = useState(false);
    const [position, setPosition] = useState({ top: -9999, left: -9999 }); // Initially off-screen
    const tooltipRef = useRef(null);

    const handleMouseEnter = (e) => {
        updateTooltipPosition(e); // Update position first
        setShow(true); // Then show the tooltip
    };

    const handleMouseMove = (e) => {
        if (show) {
            updateTooltipPosition(e);
        }
    };

    const handleMouseLeave = () => {
        setShow(false);
    };

    const updateTooltipPosition = (e) => {
        const tooltipElement = tooltipRef.current;
        if (tooltipElement) {
            const tooltipWidth = tooltipElement.offsetWidth;
            const tooltipHeight = tooltipElement.offsetHeight;

            // Calculate position
            const offsetX = 10; // Offset from the cursor
            const offsetY = 20; // Offset from the cursor

            let top = e.clientY + offsetY;
            let left = e.clientX + offsetX;

            // Adjust position to keep tooltip inside the viewport
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            if (left + tooltipWidth > viewportWidth) {
                left = e.clientX - tooltipWidth - offsetX;
            }

            if (top + tooltipHeight > viewportHeight) {
                top = e.clientY - tooltipHeight - offsetY;
            }

            setPosition({ top, left });
        }
    };

    return (
        <div
            className="tooltip-container font-hassText55 w-fit"
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {show && (
                <div
                    className="tooltip-text absolute z-30 font-black bg-black dark:bg-white text-white dark:text-black transition-opacity duration-75"
                    style={{
                        top: `${position.top}px`,
                        left: `${position.left}px`,
                        opacity: show ? 1 : 0 // Control visibility with opacity
                    }}
                    ref={tooltipRef}
                >
                    {tooltipText}
                </div>
            )}
        </div>
    );
}

export default Tooltip;
