import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Research from './pages/Research';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';
import SmartVitale from './pages/projects/hs/smartvitale';

function App() {
  return (
    <div className='flex flex-col min-h-screen w-full'>
      <div className='flex-grow dark:bg-neutral-950 bg-white mt-[60px] w-full'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/research" element={<Research />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/hs/smartvitale" element={<SmartVitale />} />
        </Routes>
      </div>
      <footer className='Footer bg-black text-white flex justify-between py-4 w-full'>
        <p className='font-hassText55 text-sm p-4'>&copy; 2024 Alexandre David, Wyoming</p>
        <div className='flex space-x-10 p-4 mr-10'>
          <div className='flex flex-col space-y-2'>
            <p className='font-hassText55 text-sm font-bold'>Contact:</p>
            <p className='font-hassText55 text-sm'>
              Email: <a href='mailto:uw.abrunet@gmail.com' className='mt-[2px] text-white hover:border-b-2 hover:border-white border-b-2 border-black'>uw.abrunet@gmail.com</a>
            </p>
            <p className='font-hassText55 text-sm'>
              Phone: <a href='tel:+1-307-256-2020' className='mt-[2px] text-white hover:border-b-2 hover:border-white border-b-2 border-black'>+1 (307) 256-2020</a>
            </p>
          </div>
          <div className='flex flex-col space-y-2'>
            <p className='font-hassText55 text-sm font-bold'>Social:</p>
            <p className='font-hassText55 text-sm'>
              LinkedIn: <a href="https://www.linkedin.com/in/alexandre-brunet-40b217297/" className='mt-[2px] text-white hover:border-b-2 hover:border-white border-b-2 border-black'>Alexandre Brunet</a>
            </p>
            <p className='font-hassText55 text-sm'>
              GitHub: <a href='https://github.com/xanderbrunet' className='mt-[2px] text-white hover:border-b-2 hover:border-white border-b-2 border-black'>xanderbrunet</a>
            </p>
            <p className='font-hassText55 text-sm'>Twitter: </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
