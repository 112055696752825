import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import "./styles/output.css"; 
import "./index.css";

import App from './App';
import Navbar from './components/Navbar';

import { Helmet } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <title>Alexandre David</title>
          <link rel='icon' href='/favicon.ico' />
          <meta name="description" content="Personal website of Alexandre David" />
          <meta property="og:title" content="Alexandre David" />
          <meta property="og:description" content="Personal website of Alexandre David." />
          <meta property="og:image" content="https://adavid03.com/icons/icon-512x512.png" />
          <meta property="og:url" content="https://adavid03.com" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Alexandre David</title>
          <meta
            name="description"
            content="Personal website of Alexandre David"
          />
        </Helmet>
        <Navbar />
        <App />
        
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
