import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../components/Tooltip';
import "../styles/output.css";

function Projects() {
    return (
        <div className="Container">
            <h1 className="text-2xl font-hassDisplayBlack text-black dark:text-white tracking-wide">Projects</h1>
            <input type="text" className="lg:w-1/2 md:w-full sm:w-full mt-5 p-2 border-2 border-neutral-200 text-black dark:text-white dark:border-neutral-800 rounded-md bg-transparent" placeholder="Search projects..." />
            <h1 className="mt-10 text-xl font-light font-hassDisplay75 text-neutral-600 dark:text-neutral-400 tracking-wide">High-School:</h1>
            <Tooltip tooltipText="adavid03.com/projects/hs/smartvitale" className='w-fit'>
                <Link to="/projects/hs/smartvitale" className="w-fit mt-[2px] text-black dark:text-white lg:text-xl md:text-md sm:text-md font-hassText55 hover:border-b-2 dark:hover:border-white hover:border-black border-b-2 dark:border-black border-white">
                    Smart'Vitale: Automated Card Disinfection System
                </Link>
            </Tooltip>
            <div className="flex gap-1 mt-2 overflow-x-auto thin-scroll">
                <div className="flex items-center bg-green-600 text-white px-2 py-1 rounded-md">
                    <p className="text-sm font-hassText55">Completed</p>
                </div>
                <div className="flex items-center bg-yellow-500 text-white px-2 py-1 rounded-md">
                    <p className="text-sm font-hassText55">Award Winning</p>
                </div>
                <div className="flex items-center bg-neutral-100 dark:bg-neutral-800 text-neutral-600 dark:text-neutral-400 px-2 py-1 rounded-md">
                    <p className="text-sm font-hassText55">Engineering</p>
                </div>
                <div className="flex items-center bg-neutral-100 dark:bg-neutral-800 text-neutral-600 dark:text-neutral-400 px-2 py-1 rounded-md">
                    <p className="text-sm font-hassText55">MATLAB</p>
                </div>
                <div className="flex items-center bg-neutral-100 dark:bg-neutral-800 text-neutral-600 dark:text-neutral-400 px-2 py-1 rounded-md">
                    <p className="text-sm font-hassText55">Electronics</p>
                </div>
            </div>
            <h3 className="mt-1 text-md font-hassText55 text-neutral-300 dark:text-neutral-700 tracking-wide">Alexandre David, Paul Guillemin, Adrien Delhomau, Victor Sin Sohn</h3>
        </div>
    );
}

export default Projects;
